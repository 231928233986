import { useRouter } from "next/router";
import { useEffect } from "react";
import { useCookies } from 'react-cookie';

function SetCookies() {
    const router = useRouter();
    const [cookie, setCookie] = useCookies();

    useEffect(() => {
        const queryParams = router.query;
        const trackingUrlParams = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_term', 'utm_content', 'gclid'];
        if (queryParams.utm_source) {
            trackingUrlParams.forEach((el) => {
                setCookie(el, JSON.stringify(queryParams[el] || cookie[el] || ''), {
                    path: '/',
                    maxAge: 28 * 86400, // 1 day = 86400 seconds
                    sameSite: true,
                })
            })
        }
    }, [router.query])

    return (<></>)
}

export default SetCookies;