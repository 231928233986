import { useEffect, useState } from 'react';
import { AppProps } from 'next/dist/next-server/lib/router/router';
import { withRouter } from 'next/router';
import { CookiesProvider } from 'react-cookie';
import { GTMPageView } from 'utils/gtm';
import { useStores } from 'hooks/useStores';
import * as gtag from 'utils/gtag';
import { MIXPANEL_TOKEN, PROD_MIXPANEL_TOKEN } from 'utils/data';
import mixpanel from 'mixpanel-browser';
import { handleMPPageVisit } from 'utils/mixpanelEvents';
import SetCookies from 'components/cookies/SetCookies';
import 'styles/globals.css';
import Head from 'next/head';
import config from 'config';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import theme from 'components/generics/theme';
import Notification from 'components/styledComponents/Notification';

const isProduction = process.env.NODE_ENV === 'production';
const trackingHost = 'stayqrious.com';

function MyApp({ Component, pageProps, ...props }: AppProps) {
  const { router } = props;
  const { userStore, commonStore, authStore, globalProps } = useStores();
  const [loading, setLoading] = useState(false);
  const [track, setTrack] = useState(false);

  if (isProduction) {
    mixpanel.init(PROD_MIXPANEL_TOKEN);
  } else {
    mixpanel.init(MIXPANEL_TOKEN);
  }

  // useEffect(() => {
  //     // Remove the server-side injected CSS.
  //     const jssStyles = document.querySelector('#jss-server-side');
  //     if (jssStyles) {
  //         jssStyles.parentElement.removeChild(jssStyles);
  //     }
  // }, []);

  const handleRouteChange = (url: any) => {
    if (isProduction) {
      GTMPageView(url);
      gtag.pageView(url);
    }

    if (router.route === '/_error') return;

    if (router.components[router.pathname].props.pageProps.meta) {
      const pageName =
        router.components[router.pathname].props.pageProps.meta.mp_page_name ||
        null;

      mixpanel.register({
        'Page Name': pageName || null
      });

      const query = router.query;
      if (query.school) {
        mixpanel.register({
          School: query.school || null
        });
      }
      const clarityFunc = (window as any).clarity;
      const student = query.student;
      if (student && typeof clarityFunc === 'function') {
        clarityFunc('identify', student);
        clarityFunc('set', 'username', student);
        clarityFunc('set', 'mx_distinct_id', mixpanel.get_distinct_id());
        mixpanel.register({
          Student: student || null
        });
      }

      handleMPPageVisit({ router });
    }
    setLoading(false);
  };

  useEffect(() => {
    const handleStart = (url: string) =>
      url !== router.asPath && setLoading(true);
    // const handleComplete = (url) => (url === router.asPath) && setLoading(false);
    let pageName = null;

    if (router.route !== '/_error') {
      if (router.components[router.pathname].props.pageProps.meta) {
        pageName =
          router.components[router.pathname].props.pageProps.meta
            .mp_page_name || null;
      }

      mixpanel.register({
        'Page Name': pageName || null
      });

      handleMPPageVisit({ router });
    }

    router.events.on('routerChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routerChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (commonStore.appLoaded) {
      return;
    }

    if (!commonStore.token) {
      authStore.logout();
      commonStore.setAppLoaded();
      return;
    }

    userStore
      .pullUser()
      .then(() => {
        commonStore.setAppLoaded();
      })
      .catch((err) => {
        commonStore.setAppLoadError();
      });
  });

  useEffect(() => {
    if (window.location.hostname === trackingHost) {
      setTrack(true);
    }
  }, []);

  return (
    <>
      <Head>
        {isProduction && track && (
          <>
            {/* Zoho page sense for stayqrious.com*/}
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(w,s){
                                        var e=document.createElement("script");
                                        e.type="text/javascript";
                                        e.async=true;
                                        e.src="https://cdn-in.pagesense.io/js/stayqrious/d919bf7ce4e2433e8ea4a7d3bde4c4dd.js";
                                        var x=document.getElementsByTagName("script")[0];
                                        x.parentNode.insertBefore(e,x);
                                    })(window,"script");
                                    `
              }}
            />
            {/* TruConversion for stayqrious.com*/}
            {/* <script
                                dangerouslySetInnerHTML={{
                                    __html: `
                                        var _tip = _tip || [];
                                        (function(d,s,id){
                                            var js, tjs = d.getElementsByTagName(s)[0];
                                            if(d.getElementById(id)) { return; }
                                            js = d.createElement(s); js.id = id;
                                            js.async = true;
                                            js.src = d.location.protocol + '//app.truconversion.com/ti-js/19016/bb445.js';
                                            tjs.parentNode.insertBefore(js, tjs);
                                        }(document, 'script', 'ti-js'));
                                    `
                                }}
                            /> */}
            {/* clarity */}
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(c,l,a,r,i,t,y){
                          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                          })(window, document, "clarity", "script", "6ombbovptb");`
              }}
            />
            {/* <!-- Facebook Pixel Code --> */}
            <script
              dangerouslySetInnerHTML={{
                __html: `
                                !function(f,b,e,v,n,t,s)
                                {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
                                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                                n.queue=[];t=b.createElement(e);t.async=!0;
                                t.src=v;s=b.getElementsByTagName(e)[0];
                                s.parentNode.insertBefore(t,s)}(window, document,'script',
                                'https://connect.facebook.net/en_US/fbevents.js');
                                fbq('init', '${config.FB_PIXEL_ID}');
                                fbq('track', 'PageView');
                                `
              }}
            />
            <noscript
              dangerouslySetInnerHTML={{
                __html: `
                                <img height="1" width="1" style="display:none"
                                    src="https://www.facebook.com/tr?id=${config.FB_PIXEL_ID}&ev=PageView&noscript=1" />
                                `
              }}
            />
            {/* <!-- End Facebook Pixel Code --> */}

            {/* PostHog */}
            {/* <script
                                dangerouslySetInnerHTML={{
                                    __html: `
                                        !function(t,e){
                                            var o,n,p,r;
                                            e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
                                        posthog.init('wp8kX-fA7nHZ5j5hiNvUKqhtljRd_2fFC71clTlMpOg',{api_host:'https://p8c1b9.apps.restack.io'})
                                        `
                                }}
                            >
                            </script> */}
            {/* End PostHog */}
          </>
        )}
      </Head>

      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <CookiesProvider>
          <SetCookies />
          <Notification />
          <Component {...pageProps} />
        </CookiesProvider>
      </MuiThemeProvider>
    </>
  );
}

export default withRouter(MyApp);
