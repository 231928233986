import { createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FAA004',
      dark: '#FAA004'
    },
    secondary: {
      main: '#0485fd',
      dark: '#0485fd'
    },
    text: {
      primary: '#1F313D'
    }
  },
  typography: {
    h1: {
      fontSize: '40px',
      fontWeight: 700,
      lineHeight: '64px',
      '@media(max-width:760px)': {
        fontSize: '24px',
        lineHeight: '40px'
      }
    },
    h2: {
      fontSize: '32px',
      fontWeight: 700,
      '@media(max-width:760px)': {
        fontSize: '24px',
        lineHeight: '40px'
      }
    },
    h3: {
      fontSize: '28px',
      fontWeight: 500,
      '@media(max-width:760px)': {
        fontSize: '22px'
        // lineHeight: '40px',
      }
    },
    h4: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '30px',
      '@media(max-width:760px)': {
        fontSize: '20px'
        // lineHeight: '40px',
      }
    },
    h5: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '26px',
      '@media(max-width:760px)': {
        fontSize: '18px',
        lineHeight: '26px'
      }
    },
    h6: {
      fontSize: '14px',
      fontWeight: 600,
      '@media(max-width:760px)': {
        // fontSize: '24px',
        // lineHeight: '40px',
      }
    },
    p: {
      fontSize: '14px',
      lineHeight: '30px',
      fontWeight: 400,
      '@media(max-width:760px)': {}
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
      '@media(max-width:760px)': {
        fontSize: '16px',
        lineHeight: '24px'
      }
    },
    body2: {
      fontSize: '14px',
      lineHeight: '26px',
      fontWeight: 400,
      '@media(max-width:760px)': {
        fontSize: '14px',
        lineHeight: '24px'
      }
    }
  }
});

export default theme;
